import { call, put, select } from 'redux-saga/effects';

import api from 'api';

import { selectCurrentPlanId } from 'store/personalPlan/selectors';
import { getCompletedPlanStatisticsSuccess } from 'store/personalPlan/actions';

import { IStatisticPersonalPlanResponse } from 'types/personalPlan';

export function* getCompletedPlanStatistics() {
    try {
        const currentPlanId: number = yield select(selectCurrentPlanId);

        const personalPlan: IStatisticPersonalPlanResponse = yield call(
            api.personalPlan.getStatisticPersonalPlan,
            currentPlanId
        );

        yield put(getCompletedPlanStatisticsSuccess(personalPlan));
    } catch (error: any) {
        console.error('Complete personal plan error: ', error.message);
    }
}
