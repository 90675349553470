import { call, put, select } from 'redux-saga/effects';

import api from 'api';

import { selectCurrentPlanId, selectTotalPlanDays } from 'store/personalPlan/selectors';
import { setCurrentPlanCompletion, workoutComplete } from 'store/personalPlan/actions';
import { notifyError } from 'store/notifications/actions';

import { isLastDayOfThePlan } from 'helpers/workout';

export function* completeWorkout({ payload }: ReturnType<typeof workoutComplete>) {
    const { fact_kcal, fact_duration, onWorkoutCompleted, workoutDay } = payload;

    try {
        const currentPlanId: number = yield select(selectCurrentPlanId);
        const totalDays: number = yield select(selectTotalPlanDays);

        yield call(api.personalPlan.personalPlanForDay, {
            fact_kcal,
            fact_duration,
            id: currentPlanId,
            number: workoutDay,
        });

        if (isLastDayOfThePlan(workoutDay, totalDays)) {
            yield put(setCurrentPlanCompletion(true));
        }

        onWorkoutCompleted();
    } catch (error) {
        yield put(notifyError({ message: 'basics.appError' }));
    }
}
