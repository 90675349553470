import { call, put } from 'redux-saga/effects';

import api from 'api';

import { generatePlanRequest, getCurrentPlanSuccess, setCurrentPlanCompletion } from 'store/personalPlan/actions';

import { addWorkoutStatus } from 'helpers/personalPlan';

import { IPersonalListResponse } from 'types/personalPlan';

export function* generatePlan({ payload }: ReturnType<typeof generatePlanRequest>) {
    try {
        yield put(setCurrentPlanCompletion(false));

        const { id, current_day, days, yoga_type }: IPersonalListResponse = yield call(
            api.personalPlan.generatePersonalPlan,
            payload?.level
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ workouts: days, currentDay: current_day });

        yield put(getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type }));
    } catch (error: any) {
        console.error('Generate personal plan error: ', error.message);
    }
}
