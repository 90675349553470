import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { PRIVATE, PUBLIC } from 'router/routes';
import isPrivateRoute from 'router/helpers/isPrivateRoute';

import { getToken, removeToken, setToken } from 'api/utils/tokenManagement';

import {
    authentication,
    authenticationBySignature,
    setAuthenticationStatus,
    setAuthRedirectUrl,
} from 'store/auth/actions';

import { AccountTab } from 'containers/AccountSettings/constants';
import { SIGNATURE_PARAM_NAME, USER_ID_PARAM_NAME } from 'constants/authentication';
import { AUTH_TOKEN_NAME } from 'constants/api';

import { initAnalytics } from 'services/initAnalytics';
import { initHotjar } from 'services/Hotjar';

import { useDispatch } from 'hooks/store';

import { getAllowedAuthQueryParams } from 'helpers/authentication';

export const useAuthentication = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const allowedQueryParams = getAllowedAuthQueryParams(search);

    const isPrivate = isPrivateRoute(pathname);
    const pathWithAllowedQuery = `${pathname}${allowedQueryParams}`;

    const isBigScreen = pathWithAllowedQuery.includes('cast');

    const params = new URLSearchParams(search);

    useEffect(() => {
        const uId = params.get(USER_ID_PARAM_NAME);
        const signature = params.get(SIGNATURE_PARAM_NAME);

        initAnalytics();
        initHotjar();

        if (uId && signature) {
            const subscriptionsPage = `${PRIVATE.ACCOUNT_DEFAULT.path}/${AccountTab.Subscription}`;

            dispatch(setAuthRedirectUrl(subscriptionsPage));
            dispatch(
                authenticationBySignature({
                    uId,
                    signature,
                    onSuccess: () => {
                        navigate(subscriptionsPage);
                    },
                    onError: () => {
                        navigate(PUBLIC.LOGIN.path);
                    },
                })
            );

            return;
        }

        const tokenFromUrl = params.get(AUTH_TOKEN_NAME);

        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }

        if (!getToken()) {
            isPrivate && dispatch(setAuthRedirectUrl(pathWithAllowedQuery));

            dispatch(setAuthenticationStatus(false));

            return;
        }

        dispatch(
            authentication({
                onSuccess: () => {
                    navigate(isPrivate || isBigScreen ? pathWithAllowedQuery : PRIVATE.MAIN.path);
                },
                onError: () => {
                    removeToken();
                    isPrivate && dispatch(setAuthRedirectUrl(pathWithAllowedQuery));
                    navigate(PUBLIC.LOGIN.path);
                },
            })
        );
    }, []);
};
