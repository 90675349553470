import { call, put } from 'redux-saga/effects';

import api from 'api';

import { checkAllResumedSubscriptions } from 'store/subscriptions/helpers';
import { pauseSubscription, resumeSubscription } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { RENEW_SUCCESS_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoadCancelOffer } from 'services/analytics';

import { getRequestResumeSubscriptionErrorModalData, getResumeSuccessModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ISubscription, SubscriptionPauseType } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';

export function* requestResumeSubscription({ payload }: ReturnType<typeof resumeSubscription>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, {
                title: 'subscription.waitingModal.updatingPlan.title',
                img: null,
            })
        );

        yield call(api.subscriptions.resumeSubscription, { external_id: payload.external_id });

        const result: ISubscription[] = yield call(
            pollGetSubscriptions,
            checkAllResumedSubscriptions(payload.external_id)
        );

        const currentSubscription: ISubscription | undefined = result.find(
            ({ external_id }: ISubscription) => external_id === payload.external_id
        );

        if (currentSubscription) {
            yield call(trackScreenLoadCancelOffer, {
                screenId: RENEW_SUCCESS_SCREEN_ID,
                eventLabel: { subscription_id: payload.external_id },
            });

            yield put(
                openModal(
                    ModalName.SuccessModal,
                    getResumeSuccessModalData(currentSubscription.cancelled_at ?? currentSubscription.expired_date)
                )
            );
        }
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...getRequestResumeSubscriptionErrorModalData([payload.external_id]),
                retryAction: () =>
                    pauseSubscription({ externalIds: [payload.external_id], pause_type: SubscriptionPauseType.Pause }),
            })
        );

        console.error(error);
    }
}
