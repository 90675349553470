import { Text, Button, Box } from 'wikr-core-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo } from 'react';

import { selectCancellationCandidates } from 'store/subscriptions/selectors';

import { sendAnalyticCancelOfferClick, trackScreenLoadCancelOffer } from 'services/analytics';

import { useCancelSubscriptionFlow } from 'hooks/subscriptions';
import useModals from 'hooks/modals/useModals';

import { getValuesByKey } from 'helpers/utils';

import OutlinedButton from 'components/OutlinedButton';
import { ModalName } from 'components/Modals/types';
import FullScreenModalHeader from 'components/ModalHeaders/FullScreenModalHeader';
import Modal from 'components/Modal';
import Steps from './components/Steps';

import { CancelFlowOffers, CancelOfferAction } from 'types/subscription';

const ReminderModal = () => {
    const { closeModal, openModal } = useModals();
    const { t } = useTranslation();

    const { onCancelSubscription, onRequestSetReminder } = useCancelSubscriptionFlow();

    const cancellationCandidates = useSelector(selectCancellationCandidates);

    const subscription_id = useMemo(
        () => getValuesByKey(cancellationCandidates, 'external_id'),
        [cancellationCandidates]
    );

    const sendAnalytics = useCallback(
        (action: CancelOfferAction) => {
            sendAnalyticCancelOfferClick({
                eventLabel: { subscription_id, offer: CancelFlowOffers.Reminder },
                actionEvent: action,
            });
        },
        [subscription_id]
    );

    useEffect(() => {
        trackScreenLoadCancelOffer({
            eventLabel: { subscription_id, offer: CancelFlowOffers.Reminder },
        });
    }, [subscription_id]);

    const onBackButtonClick = () => {
        sendAnalytics(CancelOfferAction.Close);

        openModal(ModalName.CancellationReasonModal);
    };

    const onClose = useCallback(() => {
        sendAnalytics(CancelOfferAction.Close);

        closeModal();
    }, [closeModal, sendAnalytics]);

    const onCancel = () => {
        sendAnalytics(CancelOfferAction.Cancel);

        onCancelSubscription();
    };

    const onSubmit = () => {
        sendAnalytics(CancelOfferAction.Accept);

        onRequestSetReminder();
    };

    return (
        <Modal fullscreen isOpen onClose={onClose} customStyles={{ padding: 0 }} withRoundedCorners={false}>
            <FullScreenModalHeader onBackButtonClick={onBackButtonClick} closeModal={onClose} />
            <Box paddingTop={32} paddingBottom={32} className="container-sm">
                <Text medium center mb={16} type="h4" text={t('subscription.reminderModal.title')} />
                <Text
                    mb={34}
                    center
                    type="large-text"
                    color="text-secondary"
                    text={t('subscription.reminderModal.subtitle')}
                />
                <Steps />

                <Button
                    mb={16}
                    backgroundColor="primary-default"
                    text={t('subscription.reminderModal.remindBtn')}
                    onClick={onSubmit}
                    dataLocator="setReminderButton"
                />
                <OutlinedButton
                    onClick={onCancel}
                    text={t('basics.button.cancelSubscription')}
                    backgroundColor="surface-main"
                />
            </Box>
        </Modal>
    );
};

export default ReminderModal;
