import QRCode from 'qrcode.react';

import Skeleton from 'components/Skeleton';

import * as S from './styled';

interface IScanQrCodeProps {
    isLoading: boolean;
    deepLinkUrl: string;
    sizeQRCode?: number;
    sizeSkeleton?: number;
    mb?: number;
    dataLocator?: string;
}

export const ScanQrCode = ({
    isLoading,
    deepLinkUrl,
    sizeQRCode = 186,
    sizeSkeleton = 200,
    mb = 0,
    dataLocator,
}: IScanQrCodeProps) => {
    return (
        <S.ScanQrCodeWrapper
            paddingX={20}
            paddingTop={20}
            paddingBottom={20}
            borderRadius={16}
            backgroundColor="surface-default"
            size={sizeSkeleton}
            mb={mb}
            dataLocator={dataLocator}
        >
            {isLoading ? (
                <Skeleton borderRadius={8} width={sizeSkeleton} height={sizeSkeleton} />
            ) : (
                <QRCode size={sizeQRCode} value={deepLinkUrl} />
            )}
        </S.ScanQrCodeWrapper>
    );
};
