import { Box, Image, Text } from 'wikr-core-components';
import { useTranslation } from 'react-i18next';

import * as S from './styled';

import step3 from './img/step3.svg';
import step2 from './img/step2.svg';
import step1 from './img/step1.svg';
import guides from './img/guides.svg';
import collection from './img/collection.svg';

const GUIDES = [
    'subscription.reminderModal.accessItem2.guide1',
    'subscription.reminderModal.accessItem2.guide2',
    'subscription.reminderModal.accessItem2.guide3',
    'subscription.reminderModal.accessItem2.guide4',
    'subscription.reminderModal.accessItem2.guide5',
    'subscription.reminderModal.accessItem2.guide6',
];

const Steps = () => {
    const { t } = useTranslation();

    return (
        <Box paddingBottom={34} dataLocator="reminderBlock">
            <S.StepItem>
                <S.ImageWrapper>
                    <Image src={step1} alt="image step 1" maxWidth={24} isForceVisible />
                </S.ImageWrapper>
                <div>
                    <S.TitleWrapper>
                        <Text medium type="h6" text={t('subscription.reminderModal.step1.title')} />
                    </S.TitleWrapper>
                    <S.AccessItem mb={12}>
                        <S.AccessItemHeader>
                            <Image src={collection} alt="Plan & Workout Collection" maxWidth={24} />
                            <Text medium type="large-text" text={t('subscription.reminderModal.accessItem1.title')} />
                        </S.AccessItemHeader>
                        <Text
                            type="small-text"
                            color="text-secondary"
                            text={t('subscription.reminderModal.accessItem1.description')}
                        />
                    </S.AccessItem>
                    <S.AccessItem mb={40}>
                        <S.AccessItemHeader>
                            <Image src={guides} alt="Helpful guides" maxWidth={24} />
                            <Text medium type="large-text" text={t('subscription.reminderModal.accessItem2.title')} />
                        </S.AccessItemHeader>
                        <Text
                            mb={8}
                            type="small-text"
                            color="text-secondary"
                            text={t('subscription.reminderModal.accessItem2.description')}
                        />
                        {GUIDES.map((item) => (
                            <S.GuideItem key={item} type="small-text" color="text-secondary" text={t(item)} />
                        ))}
                    </S.AccessItem>
                </div>
            </S.StepItem>

            <S.StepItem mb={13}>
                <S.ImageWrapper>
                    <Image src={step2} alt="image step 2" maxWidth={24} isForceVisible />
                </S.ImageWrapper>

                <div>
                    <S.TitleWrapper>
                        <Text medium type="h6" text={t('subscription.reminderModal.step2.title')} />
                    </S.TitleWrapper>
                    <Text
                        type="small-text"
                        color="text-secondary"
                        text={t('subscription.reminderModal.step2.description')}
                        mb={27}
                    />
                </div>
            </S.StepItem>

            <S.StepItem>
                <S.ImageWrapper>
                    <Image src={step3} alt="image step 3" maxWidth={24} isForceVisible />
                </S.ImageWrapper>

                <div>
                    <S.TitleWrapper>
                        <Text medium type="h6" text={t('subscription.reminderModal.step3.title')} />
                    </S.TitleWrapper>
                    <Text
                        type="small-text"
                        color="text-secondary"
                        text={t('subscription.reminderModal.step3.description')}
                    />
                </div>
            </S.StepItem>
        </Box>
    );
};

export default Steps;
