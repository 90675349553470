import { call, delay, put } from 'redux-saga/effects';

import api from 'api';

import { updateUserEmail, updateUserError, updateUserSuccess } from 'store/user/actions';
import { notifyError, notifySuccess } from 'store/notifications/actions';

import { UserResponse } from 'types/store/userResponse';

export function* updateUserEmailRequest({
    payload: { email, onSuccess, onError },
}: ReturnType<typeof updateUserEmail>) {
    try {
        const userData: UserResponse = yield call(api.user.update, {
            email,
        });

        yield put(updateUserSuccess(userData));
        yield put(notifySuccess({ message: 'change.email.success' }));

        // Fix closing notification on redirect
        yield delay(500);

        onSuccess && onSuccess();
    } catch (error: any) {
        yield put(updateUserError(error.error));
        yield put(notifyError({ message: 'change.email.error' }));

        onError && onError();
    }
}
