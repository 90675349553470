import { put } from 'redux-saga/effects';

import { setCancellationCandidates } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { getCancelSubscriptionSuccessModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ICancelSubscription } from 'types/subscription';

export function* callSuccessCancellingBehaviour({
    cancellationCandidates,
}: {
    cancellationCandidates: ICancelSubscription[];
}) {
    const [firstCandidate, secondCandidate] = cancellationCandidates;

    yield put(
        openModal(
            ModalName.SuccessModal,
            getCancelSubscriptionSuccessModalData([
                firstCandidate.currentSubscription,
                secondCandidate?.currentSubscription,
            ])
        )
    );
    yield put(setCancellationCandidates(null));
}
