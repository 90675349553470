import { Provider } from 'wikr-core-analytics';
import { call, put } from 'redux-saga/effects';

import api from 'api';

import { getCurrentUserError, getCurrentUserRequest, getCurrentUserSuccess } from 'store/user/actions';
import { notifyError } from 'store/notifications/actions';

import { userAPIResponseTransformer } from 'helpers/api';

import { UserResponse } from 'types/store/userResponse';

export function* getCurrentUser({ payload }: ReturnType<typeof getCurrentUserRequest>) {
    try {
        const userData: UserResponse = yield call(api.user.getUser);

        yield put(getCurrentUserSuccess(userAPIResponseTransformer(userData)));
        Provider.setUserId(userData.user_id);

        payload?.onSuccess?.(userData);
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));
        yield put(getCurrentUserError(error.error));
        payload?.onError?.(error);
    }
}
