import * as actionTypes from './actionTypes';

import { ISocketMessage } from './types';

export const getBigScreenDeepLinkRequest = () =>
    ({
        type: actionTypes.GET_BIG_SCREEN_DEEP_LINK_REQUEST,
    } as const);

export const getBigScreenDeepLinkSuccess = (payload: { url: string; token: string }) =>
    ({
        type: actionTypes.GET_BIG_SCREEN_DEEP_LINK_SUCCESS,
        payload,
    } as const);

export const setSocketMessage = (payload: ISocketMessage) =>
    ({
        type: actionTypes.SET_SOCKET_MESSAGE,
        payload,
    } as const);
