import { all, takeLatest } from 'redux-saga/effects';

import { sendSubscriptionFeedback } from './sendSubscriptionFeedback';
import { requestSetReminder } from './requestSetReminder';
import { requestResumeSubscription } from './requestResumeSubscription';
import { requestRestoreSubscription } from './requestRestoreSubscription';
import { requestPauseSubscription } from './requestPauseSubscription';
import { makeSubscriptionCancelling } from './makeSubscriptionCancelling';
import { getSubscription } from './getSubscription';
import { getDiscountSubscription } from './getDiscountSubscription';
import { changeCurrentSubscription } from './changeCurrentSubscription';
import * as actionTypes from '../actionTypes';

export default function* watchSubscriptions() {
    yield all([
        takeLatest(actionTypes.FETCH_USER_SUBSCRIPTIONS, getSubscription),
        takeLatest(actionTypes.CANCEL_SUBSCRIPTION, makeSubscriptionCancelling),
        takeLatest(actionTypes.SEND_SUBSCRIPTION_FEEDBACK, sendSubscriptionFeedback),
        takeLatest(actionTypes.UPDATE_SUBSCRIPTION, changeCurrentSubscription),
        takeLatest(actionTypes.FETCH_DISCOUNT_SUBSCRIPTION, getDiscountSubscription),
        takeLatest(actionTypes.RESTORE_SUBSCRIPTION, requestRestoreSubscription),
        takeLatest(actionTypes.PAUSE_SUBSCRIPTION, requestPauseSubscription),
        takeLatest(actionTypes.RESUME_SUBSCRIPTION, requestResumeSubscription),
        takeLatest(actionTypes.SET_REMINDER, requestSetReminder),
    ]);
}
