import { call, delay } from 'redux-saga/effects';

import { ISubscription } from 'types/subscription';

import { getSubscription } from './getSubscription';

export function* pollGetSubscriptions(conditionCheck: (data: ISubscription[]) => boolean) {
    let isConditionSatisfied = false;

    let result: ISubscription[] = [];

    while (!isConditionSatisfied) {
        result = yield call(getSubscription);

        isConditionSatisfied = !result || conditionCheck(result);

        if (!isConditionSatisfied) {
            // Wait for 2 seconds before polling again
            yield delay(2000);
        }
    }

    return result ?? [];
}
