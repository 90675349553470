import { call, put, select } from 'redux-saga/effects';

import api from 'api';

import { UserStore } from 'store/user/types';
import { selectCurrentUser } from 'store/user/selectors';
import { getWorkoutQueryParams } from 'store/personalPlan/helpers';
import { getCurrentPlanSuccess, getWorkoutSuccess, setCurrentPlanCompletion } from 'store/personalPlan/actions';

import { isWholePlanCompleted } from 'helpers/workout';
import { addWorkoutStatus } from 'helpers/personalPlan';

import { IPersonalListResponse, IWorkoutResponse } from 'types/personalPlan';

export function* getCurrentPlan() {
    try {
        const { id, current_day, days, yoga_type }: IPersonalListResponse = yield call(
            api.personalPlan.getCurrentPersonalPlan
        );

        const { workouts, availableCurrentDay } = addWorkoutStatus({ workouts: days, currentDay: current_day });
        const availableCurrentDayIndex = availableCurrentDay - 1;
        const user: UserStore = yield select(selectCurrentUser);
        const workoutParams = getWorkoutQueryParams({ currentDay: days[availableCurrentDayIndex], user, yoga_type });

        yield put(getCurrentPlanSuccess({ id, current_day: availableCurrentDay, days: workouts, yoga_type }));

        if (isWholePlanCompleted(workouts)) yield put(setCurrentPlanCompletion(true));

        const workout: IWorkoutResponse = yield call(api.personalPlan.getWorkout, workoutParams);

        yield put(getWorkoutSuccess(workout));
    } catch (error: any) {
        console.error('Get personal plan error: ', error.message);
    }
}
