import { notify, notifyError, notifySuccess } from 'store/notifications/actions';

import { NOTIFY_STATUSES } from 'constants/notifications';

import toast from 'services/toast';

export const displayErrorNotification = ({ payload }: ReturnType<typeof notifyError>) => {
    toast({ ...payload, status: NOTIFY_STATUSES.ERROR });
};

export const displaySuccessNotification = ({ payload }: ReturnType<typeof notifySuccess>) => {
    toast({ ...payload, status: NOTIFY_STATUSES.SUCCESS });
};

export const displayNotification = ({ payload }: ReturnType<typeof notify>) => {
    toast({ ...payload, status: NOTIFY_STATUSES.DEFAULT });
};
