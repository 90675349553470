import { call, put } from 'redux-saga/effects';

import api from 'api';

import { notifyError } from 'store/notifications/actions';

import { getBigScreenDeepLinkSuccess } from '../actions';

export function* getBigScreenSaga() {
    try {
        const deepLink: { url: string; token: string } = yield call(api.user.getChannelDeepLink);

        yield put(getBigScreenDeepLinkSuccess(deepLink));
    } catch (error: any) {
        yield put(notifyError({ message: 'message.error.somethingWentWrong' }));
    }
}
