import { call } from 'redux-saga/effects';

import { IResultResponse } from 'api/types/response';
import api from 'api';

import {
    sendAnalyticFrontCancelSubscriptionRequest,
    sendAnalyticSubscriptionCancelError,
    sendAnalyticSubscriptionCancelSuccess,
} from 'services/analytics';

import { CancellationReasonRetenoValue } from 'components/Modals/Subscriptions/CancellationReasonModal/types';

import { ICancelSubscription } from 'types/subscription';

export function* callUnsubscribe(
    { currentSubscription }: { currentSubscription: ICancelSubscription['currentSubscription'] },
    successCount: { current: number },
    errorCount: { current: string[] },
    cancelReason: CancellationReasonRetenoValue | null
) {
    try {
        sendAnalyticFrontCancelSubscriptionRequest();

        const response: IResultResponse = yield call(api.subscriptions.unsubscribe, {
            external_id: currentSubscription.external_id,
            ...(cancelReason && { cancellation_reason: cancelReason }),
        });

        if (!response.result) {
            throw new Error('Subscription is not cancelled');
        }

        sendAnalyticSubscriptionCancelSuccess(currentSubscription.external_id);

        successCount.current += 1;
    } catch (error) {
        errorCount.current.push(currentSubscription.external_id);

        sendAnalyticSubscriptionCancelError(currentSubscription.external_id);
    }
}
