import { all, takeLatest } from 'redux-saga/effects';

import { displayErrorNotification, displayNotification, displaySuccessNotification } from './displayNotifications';
import * as actionTypes from '../actionTypes';

export default function* watchNotifications() {
    yield all([
        takeLatest(actionTypes.DISPLAY_ERROR_NOTIFICATION, displayErrorNotification),
        takeLatest(actionTypes.DISPLAY_DEFAULT_NOTIFICATION, displayNotification),
        takeLatest(actionTypes.DISPLAY_SUCCESS_NOTIFICATION, displaySuccessNotification),
    ]);
}
