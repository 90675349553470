import { all, takeLatest } from 'redux-saga/effects';

import { signInSaga } from './signInSaga';
import { sendTemporaryPasswordSaga } from './sendTemporaryPasswordSaga';
import { logoutSaga } from './logoutSaga';
import { authenticateSaga } from './authenticateSaga';
import { authenticateBySignatureSaga } from './authenticateBySignatureSaga';
import * as actionTypes from '../actionTypes';

export { signInSaga } from './signInSaga';
export { authenticateSaga } from './authenticateSaga';
export { authenticateBySignatureSaga } from './authenticateBySignatureSaga';
export { logoutSaga } from './logoutSaga';

export default function* watchAuth() {
    yield all([
        takeLatest(actionTypes.LOG_OUT, logoutSaga),
        takeLatest(actionTypes.SIGN_IN_REQUEST, signInSaga),
        takeLatest(actionTypes.AUTHENTICATE, authenticateSaga),
        takeLatest(actionTypes.AUTHENTICATE_BY_SIGNATURE, authenticateBySignatureSaga),
        takeLatest(actionTypes.SEND_TEMPORARY_PASSWORD, sendTemporaryPasswordSaga),
    ]);
}
