import { call } from 'redux-saga/effects';

import api from 'api';

import { sendFeedback } from 'store/subscriptions/actions';

export function* sendSubscriptionFeedback({ payload }: ReturnType<typeof sendFeedback>) {
    try {
        yield call(api.subscriptions.sendFeedback, payload);
    } catch (error) {
        console.error(error);
    }
}
