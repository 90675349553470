import { all, takeLatest } from 'redux-saga/effects';

import * as actionTypes from 'store/personalPlan/actionTypes';

import { getCurrentPlan } from './getCurrentPlan';
import { getCompletedPlanStatistics } from './getCompletedPlanStatistics';
import { generatePlan } from './generatePlan';
import { completeWorkout } from './completeWorkout';

export default function* watchUser() {
    yield all([
        takeLatest(actionTypes.GET_COMPLETED_PLAN_STATISTICS, getCompletedPlanStatistics),
        takeLatest(actionTypes.GET_CURRENT_PLAN, getCurrentPlan),
        takeLatest(actionTypes.GENERATE_PLAN, generatePlan),
        takeLatest(actionTypes.COMPLETE_WORKOUT, completeWorkout),
    ]);
}
