import { all, call, put } from 'redux-saga/effects';

import api from 'api';

import { checkSubscriptionsWithReminders } from 'store/subscriptions/helpers';
import { setReminder } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { CANCEL_OFFER_SUCCESS_SCREEN_ID } from 'constants/analytics';

import { trackScreenLoadCancelOffer } from 'services/analytics';

import { reminderSuccessModalData, setReminderErrorModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { CancelFlowOffers } from 'types/subscription';

import { pollGetSubscriptions } from './pollGetSubscriptions';

export function* requestSetReminder({ payload }: ReturnType<typeof setReminder>) {
    try {
        yield put(
            openModal(ModalName.WithLoaderModal, {
                title: 'basics.loading',
                img: null,
                subtitle: null,
            })
        );

        yield all(payload.externalIds.map((external_id) => call(api.subscriptions.setReminder, { external_id })));

        yield call(pollGetSubscriptions, checkSubscriptionsWithReminders(payload.externalIds));

        yield put(openModal(ModalName.SuccessModal, reminderSuccessModalData));

        yield call(trackScreenLoadCancelOffer, {
            screenId: CANCEL_OFFER_SUCCESS_SCREEN_ID,
            eventLabel: { subscription_id: payload.externalIds, offer: CancelFlowOffers.Reminder },
        });
    } catch (error) {
        yield put(
            openModal(ModalName.RetryErrorModal, {
                ...setReminderErrorModalData,
                retryAction: () => setReminder(payload),
            })
        );
    }
}
