import { CancellationReasonRetenoValue, IReason } from './types';

import { CancellationFlowStrategy } from '../CancellationStrategies/enums';

export const reasons: IReason[] = [
    {
        title: 'subscription.cancellationReason.forgetToCancel.title',
        value: CancellationReasonRetenoValue.ForgetToCancel,
        // flow: CancellationFlowStrategy.Reminder,
    },
    {
        title: 'subscription.cancellationReason.expensive.title',
        value: CancellationReasonRetenoValue.TooExpensive,
        flow: CancellationFlowStrategy.Discount,
    },
    {
        title: 'subscription.cancellationReason.noTime.title',
        value: CancellationReasonRetenoValue.HaveNoTime,
        flow: CancellationFlowStrategy.Pause,
    },
    {
        title: 'subscription.cancellationReason.noNeed.title',
        value: CancellationReasonRetenoValue.NoNeedForApp,
        flow: CancellationFlowStrategy.Pause,
    },
    {
        title: 'subscription.cancellationReason.notEnjoying.title',
        value: CancellationReasonRetenoValue.DontLikeWorkouts,
        flow: CancellationFlowStrategy.ChangePlan,
    },
    {
        title: 'subscription.cancellationReason.notMeetExpectations.title',
        value: CancellationReasonRetenoValue.DoesntMeetExpectations,
        flow: CancellationFlowStrategy.FreeMonth,
    },
    {
        title: 'subscription.cancellationReason.cantLogin.title',
        value: CancellationReasonRetenoValue.CantLogin,
        flow: CancellationFlowStrategy.HelpLogin,
    },
    {
        title: 'subscription.cancellationReason.other.title',
        flow: CancellationFlowStrategy.Discount,
        value: CancellationReasonRetenoValue.Other,
    },
];
