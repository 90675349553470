import { put } from 'redux-saga/effects';

import { cancelSubscription, setCancellationCandidates } from 'store/subscriptions/actions';
import { openModal } from 'store/modals/actions';

import { getCancelSubscriptionErrorModalData } from 'helpers/subscriptions';

import { ModalName } from 'components/Modals/types';

import { ICancelSubscription } from 'types/subscription';

export function* callErrorCancellingBehaviour({
    errorCount,
    cancellationCandidates,
}: {
    errorCount: Record<string, string[]>;
    cancellationCandidates: ICancelSubscription[];
}) {
    const subscriptionsCancellationFailedPartially = errorCount.current.length < cancellationCandidates.length;

    if (subscriptionsCancellationFailedPartially) {
        const candidate = cancellationCandidates.find(
            (e) => e.currentSubscription.external_id === errorCount.current[0]
        );

        yield put(setCancellationCandidates(candidate ? [candidate.currentSubscription] : null));
    }

    yield put(
        openModal(ModalName.RetryErrorModal, {
            ...getCancelSubscriptionErrorModalData({
                isSingleError: errorCount.current.length > 1,
                subscriptionsCancellationFailedPartially,
            }),
            retryAction: () => cancelSubscription(cancellationCandidates),
        })
    );
}
