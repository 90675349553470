import * as action from './actions';

import * as actionTypes from './actionTypes';

import { InferValueTypes } from 'types/commonTypes';
import { BigScreenStoreType } from './types';

export const initialState: BigScreenStoreType = {
    bigScreenDeepLink: null,
    socketMessage: null,
    bigScreenToken: null,
};

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const bigScreenReducer = (state: BigScreenStoreType = initialState, action: ActionType): BigScreenStoreType => {
    switch (action.type) {
        case actionTypes.GET_BIG_SCREEN_DEEP_LINK_SUCCESS:
            return { ...state, bigScreenDeepLink: action.payload.url, bigScreenToken: action.payload.token };

        case actionTypes.SET_SOCKET_MESSAGE:
            return { ...state, socketMessage: action.payload };

        default:
            return state;
    }
};

export default bigScreenReducer;
