import { call, delay, put } from 'redux-saga/effects';

import api from 'api';

import { fetchDiscountSubscription, setDiscountSubscription } from 'store/subscriptions/actions';

import { IDiscountSubscription } from 'types/subscription';

export function* getDiscountSubscription({ payload }: ReturnType<typeof fetchDiscountSubscription>) {
    try {
        const response: IDiscountSubscription = yield call(api.subscriptions.getDiscountSubscription, payload);

        yield delay(3000);
        yield put(setDiscountSubscription(response));
    } catch (error) {
        console.error(error);
    }
}
