import { Box } from 'wikr-core-components';
import styled from 'styled-components';

export const ScanQrCodeWrapper = styled(Box)<{ size: number }>`
    width: ${(props) => `${props.size + 40}px` || '220px'};
    height: ${(props) => `${props.size + 40}px` || '220px'};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
`;
